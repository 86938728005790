// import { lazy } from 'react';
import {Navigate, useRoutes} from 'react-router-dom';

// project import
import CommonLayout from 'layout/CommonLayout';
// import Loadable from 'components/Loadable';
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
// import ProductsList from "../pages/apps/e-commerce/products-list";
// import Product from "../pages/apps/e-commerce/product";

// render - landing page
// const PagesLanding = Loadable(lazy(() => import('pages/landing')));
// const PagesLanding = Loadable(lazy(() => import('pages/apps/e-commerce/product')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          // element: <PagesLanding />,
          element:<Navigate to="apps/e-commerce/products" replace />
        }
      ]
    },
    LoginRoutes,
    ComponentsRoutes,
    MainRoutes
  ]);
}
