// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CalendarOutlined, CustomerServiceOutlined, MessageOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { CalendarOutlined, CustomerServiceOutlined, MessageOutlined, ShoppingCartOutlined, UserOutlined };

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  type: 'group',
  children: [
    // {
    //   id: 'chat',
    //   title: <FormattedMessage id="chat" />,
    //   type: 'item',
    //   url: '/apps/chat',
    //   icon: icons.MessageOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'calendar',
    //   title: <FormattedMessage id="calendar" />,
    //   type: 'item',
    //   url: '/apps/calendar',
    //   icon: icons.CalendarOutlined
    // },
    // {
    //   id: 'customer',
    //   title: <FormattedMessage id="customer" />,
    //   type: 'collapse',
    //   icon: icons.CustomerServiceOutlined,
    //   children: [
    //     {
    //       id: 'customer-list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/apps/customer/list'
    //     }
    //   ]
    // },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.UserOutlined,
    //   children: [
    //     {
    //       id: 'user-profile',
    //       title: <FormattedMessage id="user-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/user/personal'
    //     },
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/basic'
    //     },
    //     {
    //       id: 'user-list',
    //       title: <FormattedMessage id="user-list" />,
    //       type: 'item',
    //       url: '/apps/profiles/user-list'
    //     },
    //     {
    //       id: 'user-card',
    //       title: <FormattedMessage id="user-card" />,
    //       type: 'item',
    //       url: '/apps/profiles/user-card'
    //     }
    //   ]
    // },

    // {
    //   id: 'chat',
    //   title: <FormattedMessage id="chat" />,
    //   type: 'item',
    //   url: '/apps/chat',
    //   icon: icons.MessageOutlined,
    //   breadcrumbs: false
    // },

    {
      id: 'e-commerce',
      title: <FormattedMessage id="e-commerce" />,
      type: 'item',
      icon: icons.ShoppingCartOutlined,
      url: '/apps/e-commerce/products',
      breadcrumbs: true
      // children: [
      //   {
      //     id: 'products',
      //     title: <FormattedMessage id="products" />,
      //     type: 'item',
      //     url: '/apps/e-commerce/products'
      //   },
        // {
        //   id: 'product-details',
        //   title: <FormattedMessage id="product-details" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/product-details/1',
        //   breadcrumbs: false
        // },
        // {
        //   id: 'product-list',
        //   title: <FormattedMessage id="product-list" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/product-list',
        //   breadcrumbs: false
        // },
        // {
        //   id: 'add-new-product',
        //   title: <FormattedMessage id="add-new-product" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/add-new-product'
        // },
        // {
        //   id: 'checkout',
        //   title: <FormattedMessage id="checkout" />,
        //   type: 'item',
        //   url: '/apps/e-commerce/checkout'
        // }
      // ]
    }
  ]
};

export default applications;
