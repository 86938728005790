// third-party
import {add, sub} from 'date-fns';
import {Chance} from 'chance';

// types
import {Address, Products, Reviews} from 'types/e-commerce';

const chance = new Chance();

// product reviews list
export const productReviews: Reviews[] = [
    {
        id: '1',
        rating: 3.5,
        review: chance.paragraph({sentences: 2}),
        date: '2 hour ago',
        profile: {
            avatar: 'avatar-1.png',
            name: 'Emma Labelle',
            status: chance.bool()
        }
    },
    {
        id: '2',
        rating: 4.0,
        review: chance.paragraph({sentences: 2}),
        date: '12 hour ago',
        profile: {
            avatar: 'avatar-2.png',
            name: 'Lucifer Wing',
            status: chance.bool()
        }
    },
    {
        id: '3',
        rating: 4.5,
        review: 'Nice!',
        date: '1 day ago',
        profile: {
            avatar: 'avatar-3.png',
            name: 'John smith',
            status: chance.bool()
        }
    }
];

// billing address list
export const address: Address[] = [
    {
        id: 1,
        name: 'Ian Carpenter',
        destination: 'home',
        building: '1754 Ureate Path',
        street: '695 Newga View',
        city: 'Seporcus',
        state: 'Rhode Island',
        country: 'Belgium',
        post: 'SA5 5BO',
        phone: '+91 1234567890',
        isDefault: true
    },
    {
        id: 2,
        name: 'Ian Carpenter',
        destination: 'office',
        building: '1754 Ureate Path',
        street: '695 Newga View',
        city: 'Seporcus',
        state: 'Rhode Island',
        country: 'Belgium',
        post: 'SA5 5BO',
        phone: '+91 1234567890',
        isDefault: false
    }
];

let isAvailable = (time: string) => {
    let date = new Date();
    let time2 = date.toLocaleTimeString('en-US', {
        hour12: false,
        timeZone: 'CST',
    });
    time2 = time2.split(":")[0]
    let tempTime = 0;
    if (time.toLowerCase().includes("pm")) {
        tempTime = Number(time.toLowerCase().split("pm")[0]) + 12;
    } else {
        tempTime = Number(time.toLowerCase().split("am")[0]);
    }

    // return Number(time2) < tempTime;
    return true;
}

// products list
export const products: Products[] = [
    {
        id: 1,
        images: ['1.png'],
        name: 'Veg Momo (Veg)',
        brand: 'Didi',
        description: 'Veg Momo ',
        about:
            'Bite-size steamed dumplings made with a spoonful of - Panner, Green Onions, Red Onions, Coriander leaves, Cabbage - stuffing wrapped in dough.',
        quantity: 3,
        rating: 4.0,
        discount: 75,
        offerPrice: 6.99,
        gender: 'male',
        categories: ['fashion', 'watch'],
        colors: ['errorDark', 'errorMain', 'secondaryMain'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 8, hours: 6, minutes: 20}),
        isStock: true,
        new: 10,
        portion: 10,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 2,
        images: ['2.png'],
        name: 'Veg Momo (Veg) Frozen',
        brand: 'Didi',
        description: 'Veg Momo Frozen',
        about:
            'Bite-size steamed dumplings made with a spoonful of - Panner, Green Onions, Red Onions, Coriander leaves, Cabbage - stuffing wrapped in dough.',
        quantity: 3,
        rating: 4.0,
        discount: 75,
        offerPrice: 13.99,
        gender: 'male',
        categories: ['fashion', 'watch'],
        colors: ['errorDark', 'errorMain', 'secondaryMain'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 8, hours: 6, minutes: 20}),
        isStock: true,
        new: 10,
        portion: 25,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 3,
        images: ['3.png'],
        name: 'Veg Momo (Veg) Frozen',
        brand: 'Didi',
        description: 'Veg Momo Frozen',
        about:
            'Bite-size steamed dumplings made with a spoonful of - Panner, Green Onions, Red Onions, Coriander leaves, Cabbage - stuffing wrapped in dough.',
        quantity: 3,
        rating: 4.0,
        discount: 75,
        offerPrice: 26.99,
        gender: 'male',
        categories: ['fashion', 'watch'],
        colors: ['errorDark', 'errorMain', 'secondaryMain'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 8, hours: 6, minutes: 20}),
        isStock: true,
        new: 10,
        portion: 50,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 4,
        images: ['4.png'],
        name: 'Khoa Momo (Veg)',
        brand: 'Fitbit',
        // offer: '30%',
        description: 'Khoa Momo',
        about:
            'Bite-size steamed dumplings made with a spoonful of sweet khoa stuffing wrapped in dough. Can be enjoyed as a veg momo or sweet dish.',
        quantity: 70,
        rating: 4.5,
        discount: 40,
        // salePrice: 85.0,
        offerPrice: 7.99,
        gender: 'male',
        categories: ['fashion', 'watch'],
        colors: ['primary200', 'primaryDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 4, hours: 9, minutes: 50}),
        isStock: true,
        new: 30,
        portion: 10,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 5,
        images: ['5.png'],
        name: 'Chicken Momo (Non Veg)',
        brand: 'Centrix',
        // offer: '30%',
        description: 'Chicken Momo',
        about: 'Bite-size steamed dumplings made with a spoonful of chicken stuffing wrapped in dough. Chicken fillings becomes succulent as it produces an intensively flavored broth sealed inside the wrappers. Momo is an important dish for Tibetans and Nepalese , both for everyday and festivals. Variants of the dish developed later in Nepal after which it became popular among Asians',
        quantity: 3,
        rating: 4.0,
        discount: 20,
        // salePrice: 36.0,
        offerPrice: 7.99,
        gender: 'kids',
        categories: ['fashion', 'watch'],
        colors: ['errorLight', 'warningMain'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 7, hours: 6, minutes: 45}),
        isStock: true,
        new: 40,
        portion: 10,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 6,
        images: ['6.png'],
        name: 'Malpua (Veg)',
        brand: 'Didi',
        // offer: '30%',
        // description: 'Malpua in Nepal also known as Marpa is specially made in the Kathmandu Valley which uses maida, fennel seeds, pepper corns, milk and sugar into a batter and prepared in a similar way as in India.',
        description: 'Malpua',
        about: 'Malpua, or sometimes shortened to pua, is an Indian sweet served as a dessert or a snack originating from the Indian subcontinent, popular in India, Nepal and Bangladesh.',
        quantity: 50,
        rating: 3.5,
        discount: 15,
        // salePrice: 6.99,
        offerPrice: 3.99,
        gender: 'male',
        categories: ['electronics', 'camera'],
        colors: ['warningMain', 'primary200'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 2, hours: 9, minutes: 45}),
        isStock: true,
        new: 50,
        portion: 5,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 7,
        images: ['7.png'],
        name: 'Sel Roti (Veg)',
        brand: 'Apple',
        // offer: '30%',
        description: 'Sel Roti',
        about:
            'Sel roti (Nepali: सेल रोटी) is a traditional Nepalese[1] ring-shaped sweet rice bread. It is mostly prepared during Dashain and Tihar, widely celebrated Hindu festivals in Nepal as well as Sikkim, Kalimpong and Darjeeling regions in India. The dish is popular through out Nepal and among Nepalese diaspora throughout the world. The bread is made from a batter of rice flour, water sugar, ghee, and spices which is then deep fried in cooking oil.',
        quantity: 40,
        rating: 4.5,
        discount: 10,
        offerPrice: 4.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['primaryDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: add(new Date(), {days: 6, hours: 10, minutes: 0}),
        isStock: true,
        new: 60,
        portion: 5,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 8,
        images: ['8.png'],
        name: 'Puri (Veg)',
        brand: 'Didi',
        description: 'Puri',
        about:
            'Puri (sometimes spelled as poori) is a deep-fried bread made from unleavened whole-wheat flour that originated in the Indian subcontinent. It is eaten for breakfast or as a snack or light meal. It is usually served with a savory curry or bhaji, as in puri bhaji, but may also be eaten with sweet dishes.\n' +
            '\n' +
            'Puris are most commonly served as breakfast and snacks. It is also served at special or ceremonial functions as part of ceremonial rituals along with other vegetarian food offered in Hindu prayer as prasadam.',
        quantity: 70,
        rating: 4.0,
        // discount: 16,
        offerPrice: 3.99,
        gender: 'male',
        categories: ['electronics', 'laptop'],
        colors: ['errorDark', 'secondaryMain', 'errorMain'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: add(new Date(), {days: 14, hours: 1, minutes: 55}),
        isStock: true,
        new: 70,
        portion: 5,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 9,
        images: ['9.png'],
        name: 'Halwa (Veg)',
        brand: 'Apple',
        description: '',
        about:
            'Sweetened semolina pudding with green cardamom, cashews, raisins, suji, maida, and shredded coconut. Like other Nepalese sweets, Nepalese puddings are prepared using ghee.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,
        offerPrice: 4.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 300,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 10,
        images: ['10.png'],
        name: 'Chana Aloo Sabji (Veg)',
        brand: 'Apple',
        description: 'Chana Aloo Sabji',
        about:
            'Aloo Chana is a quick and easy chickpea and potato curry, it\'s great as a midweek meal (that\'s also vegan-friendly) or as a side dish for a larger Indian feast. Packed full of spices including garam masala, smoked paprika and cumin powder – this fragrant vegetarian curry is also great for the freezer.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,
        offerPrice: 4.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 200,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    // {
    //     id: 10,
    //     image: 'prod-100.png',
    //     name: 'Veg Platter',
    //     brand: 'Apple',
    //     description: 'Veg Platter',
    //     about:
    //         'Comes with Puri, Chana Aloo Sabji, Haluwa, and Malpua',
    //     quantity: 21,
    //     rating: 4.5,
    //     discount: 30,
    //     // salePrice: 129.99,
    //     // offerPrice: 4.99,
    //     gender: 'female',
    //     categories: ['electronics', 'iphone'],
    //     colors: ['errorMain', 'successDark'],
    //     popularity: chance.natural(),
    //     date: chance.natural(),
    //     created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
    //     isStock: false,
    //     new: 80,
    //     unit: 'pieces',
    //     time: '9pm'
    // },
    {
        id: 11,
        images: ['11.png'],
        name: 'Bara (Veg)',
        brand: 'Apple',
        description: 'Bara',
        about:
            'This is a classic Nepali appetizer consisting of spiced lentil patties cooked in a thick-bottomed pan or tawa.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,
        offerPrice: 4.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 5,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 12,
        images: ['12.png'],
        name: 'Aloo Ko Achar (Veg)',
        brand: 'Apple',
        description: 'Aloo Achar',
        about:
            'Aloo ko achar is a popular Instant pickle from Nepali cuisine. Potatoes and peas tossed in mustard oil and fenugreek tadka is an Nepali Recipe of Potato curry. An easy and simple Nepalese recipe. This instant potato pickle or achar served as a side dish in \'bhoj\' that is a ceremonial feast.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,
        offerPrice: 4.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 400,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 13,
        images: ['13_1.png', '13.png'],
        name: 'Chicken Choila (Non Veg)',
        brand: 'Apple',
        description: 'Chicken Choila',
        about:
            'One of the popular Newari dish in Nepal, Choila is a famous and desired appetizer served together with Baji (beaten rice). Although it is traditionally made with buffalo meat, this particular recipe is made with chicken meat.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 7.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 200,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 14,
        images: ['14.png'],
        name: 'Veg Plate (Poori, Haluwa, Chana Aloo Sabji',
        brand: 'Apple',
        description: 'Veg Plate (Poori, Haluwa, Chana Aloo Sabji',
        about:
            'Veg Plate can be served as lunch, dinner, or breakfast. Comes with 2 homemade Pooris, delicious Mapua, Chana Aloo Sabji, and Haluwa.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 6.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 300,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 15,
        images: ['15.png'],
        name: 'Veg Plate (Rice, Chana Aloo Sabji, Pickle',
        brand: 'Apple',
        description: 'Veg Plate (Rice, Chana Aloo Sabji, Pickle',
        about: 'Veg Plate can be served as lunch, dinner, or breakfast. Comes with delicious Chana Aloo Sabji, basmati rice, radish & carrot pickle ',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 6.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 300,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 16,
        images: ['16.png'],
        name: 'Veg Plate (Plain Wheat Roti, Chana Aloo Sabji, Pickle',
        brand: 'Apple',
        description: 'Veg Plate (Plain Wheat Roti, Chana Aloo Sabji, Pickle',
        about: 'Veg Plate can be served as lunch, dinner, or breakfast. Comes with delicious Chana Aloo Sabji, basmati rice, radish & carrot pickle ',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 6.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 300,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 17,
        images: ['17.png'],
        name: 'Egg Fried Rice',
        brand: 'Apple',
        description: 'Egg Fried Rice',
        about: 'Egg Fried Rice is ideal for lunch or dinner',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 5.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 300,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 17,
        images: ['17.png'],
        name: 'Egg Fried Rice',
        brand: 'Apple',
        description: 'Egg Fried Rice',
        about: 'Egg Fried Rice is ideal for lunch or dinner',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 5.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 300,
        unit: 'grams',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 18,
        images: ['18.png', '18_1.png'],
        name: 'Veg Momo Half Tray (Veg)',
        brand: 'Apple',
        description: 'Veg Momo Half Tray.',
        about: 'Veg Momo Half Tray. Bite-size steamed dumplings made with a spoonful of - Panner, Green Onions, Red Onions, Coriander leaves, Cabbage - stuffing wrapped in dough.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 31.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 50,
        unit: 'pieces',
        time: '9pm',
        available: isAvailable('9pm'),
    },
    {
        id: 19,
        images: ['19.png', '19_1.png'],
        name: 'Gajar Ko Halwa',
        brand: 'Apple',
        description: 'Gajar Ko Halwa',
        about: 'Gajar Ko Halwa. Carrot halwa is a popular sweet made in Nepal, and it is especially popular during winters. Gajar ka halwa recipe is made with only carrots, whole (full-fat) milk, ghee and sugar. Khoa, cashew nuts, and peanuts sprinkled on top.',
        quantity: 21,
        rating: 4.5,
        discount: 30,
        // salePrice: 129.99,x
        offerPrice: 44.99,
        gender: 'female',
        categories: ['electronics', 'iphone'],
        colors: ['errorMain', 'successDark'],
        popularity: chance.natural(),
        date: chance.natural(),
        created: sub(new Date(), {days: 0, hours: 11, minutes: 10}),
        isStock: true,
        new: 80,
        portion: 1/2,
        unit: 'tray',
        time: '9pm',
        available: isAvailable('9pm'),
    }
];
